
import React from 'react';
import './one.css'
import {Link} from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import NoCrashIcon from '@mui/icons-material/NoCrash';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GradingIcon from '@mui/icons-material/Grading';

const One = () => {
    return (
        <div className="one_details">

          <div className="one_details_box">
              <div className="one_details_h">
                  <h2>Автомобили</h2>
                  <p>на выбор</p>

              </div>



              {/*<div className="one_details_bg1">*/}
              {/*    <div>*/}
              {/*        <h3>ВЫБОР АВТО</h3>*/}
              {/*        <p>Профессионально проведем подбор автомобиля, электромобиля, гибрида или других ТС согласно Вашим пожеланиям, отбирая только самые интересные варианты, отвечающие Вашему бюджету и предпочтениям.</p>*/}

              {/*    </div>*/}
              {/*</div>*/}
            {/*  <div className="one_details_bg2">*/}
            {/*      <div>*/}
            {/*          <h3>ДОСТАВКА</h3>*/}
            {/*          <p>Доставка авто происходит контейнерной переправой через океан, затем грузовым поездом в Кыргызстан.</p>*/}

            {/*      </div>*/}
            {/*</div>*/}
            {/*  <div className="one_details_bg3">*/}
            {/*      <div>*/}
            {/*          <h3>РАСТАМОЖКА АВТО</h3>*/}
            {/*          <p>Растаможка авто согласно правительственному Постановлению №317 от 10.07.2018 года, позваляет растаможить авто не старше 13 лет, по сниженным таможенным тарифам.</p>*/}

            {/*      </div>*/}
            {/*  </div>*/}
          </div>
            <div className="one_details_icons">
                <div className="messanger">
                    <Link><WhatsAppIcon/></Link>
                    <Link><TelegramIcon/></Link>
                </div>
            </div>
            <div className="one_details_text">
                <div className="details_text_box">
                    <h2>Услуги</h2>
                    <div className="text_icons">
                        <div className="text_icons_box">
                            <div className="text_icons_one">
                                <NoCrashIcon  />

                            </div>
                            <h3>выбор авто</h3>
                        </div>
                        <div className="text_icons_box">
                            <div className="text_icons_two">
                                <LocalShippingIcon />

                            </div>
                            <h3>доставка</h3>
                        </div>
                        <div className="text_icons_box">
                            <div className="text_icons_three">
                                <GradingIcon />
                            </div>
                            <h3>растаможка</h3>
                        </div>

                    </div>
                </div>

            </div>
              </div>
    );
};

export default One;
