import React from 'react';
import './two.css'
import {Link} from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PriceCheckSharpIcon from '@mui/icons-material/PriceCheckSharp';

const Two = () => {
    return (
        <div className="two_details">
            <div className="two_details_title">
                <div className="two_details_h">
                    <h2>Лучшие цены</h2>
                    <h3> Невероятные Цены на Все Ваши Любимые Товары</h3>
                    <div className="messanger_icon_two">
                        <Link><WhatsAppIcon/></Link>
                        <Link><TelegramIcon/></Link>
                    </div>
                </div>
            </div>
            {/*<h2>Основные специализации:</h2>*/}
            <div className="two_details_box">
                <div className="two_details_bg1">
                    <div>
                        <h3>Компьютеры, в т.ч. моноблоки и неттопы</h3>
                        <p></p>

                    </div>
                </div>
                <div className="two_details_bg2">
                    <div>
                        <h3>Ноутбуки, нетбуки, планшеты</h3>
                        <p></p>

                    </div>
                </div>

            </div>
            <div className="two_details_services">


                <div className="services_box">
                    <PublishedWithChangesIcon />
                    <p>Доступно для вас 24/7</p>
                </div>
                <div className="services_box">
                    <RocketLaunchIcon />
                    <p>Бесплатная доставка при заказе на сумму более 5000c</p>
                </div>
                <div className="services_box">
                    <PriceCheckSharpIcon />
                    <p>Гарантия низких цен</p>
                </div>
            </div>
            <div className="two_details_discount">
                <div className="two_details_discount_box">
                    <h3>Серверы и системы хранения данных</h3>
                    <p></p>

                </div>
            </div>
        </div>
    );
};

export default Two;
