import React from 'react';
import './five.css'

const Five = () => {
    return (
        <div className='five_details'>
            <p>Кофе напиток сваренный из обжаренных и молотых семян тропических вечнозеленых кофейных растений африканского происхождения.
                Кофе входит в тройку самых популярных напитков в мире (наряду с водой и чаем) и является одним из самых прибыльных международных товаров.
                Хотя кофе является основой для бесконечного множества напитков, его популярность в основном связана с его бодрящим эффектом,
                который производится кофеином, алкалоидом, присутствующим в кофе. Два вида кофейных растений, Coffea arabica и C. canephora,
                обеспечивают почти все потребление в мире. Арабика считается более мягким и ароматным напитком, чем робуста, основной сорт C. canephora.
                Более плоские и удлиненные зерна арабики более распространены, чем робуста, но более нежные и уязвимые для вредителей,
                требующие прохладного субтропического климата; Арабика должна расти на больших высотах (2 000–6 500 футов [600–2 000 метров]),
                ей требуется много влаги и довольно специфические требования к тени. Латинская Америка, Восточная Африка,
                Азия и Аравия являются ведущими производителями кофе арабика. Более округлые и выпуклые зерна робусты, как следует из названия,
                более выносливы и могут расти на более низких высотах (от уровня моря до 2000 футов). Кофе робуста дешевле в производстве,
                содержит в два раза больше кофеина, чем арабика, и, как правило, является предпочтительным зерном для недорогих коммерческих брендов кофе.
                Западная и Центральная Африка, Юго-Восточная Азия и Бразилия являются основными производителями кофе Робуста.
            </p>
        </div>
    );
};

export default Five;
