import React from 'react';
import logo from "../../images/elementm3.png"
import './navbar.css'
import {Link} from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

const Navbar = () => {
    return (
        <>
            <div className="navbar-box">
                <div className="messanger_icon">
                    <Link><WhatsAppIcon/></Link>
                    <Link><TelegramIcon/></Link>
                </div>
                <Link to="/">
                    <div className="logo">
                        {/*Element M*/}
                        <img src={logo} alt="elementm_logo"/>
                    </div>
                </Link>

                <div className="contact">
                    <p>customs@elementm.kg</p>
                    <p>+996778128698</p>
                </div>
            </div>
            <div className="navbar">

            </div>

        </>
            );
};

export default Navbar;
