import React from 'react';
import './four.css'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import tools1 from "../../images/tools1.jpg"
import tools2 from "../../images/tools2.jpg"
import tools3 from "../../images/tools3.jpg"
import tools4 from "../../images/tools4.jpg"
import tools5 from "../../images/tools5.jpg"
import tools6 from "../../images/tools6.jpg"
import tools7 from "../../images/tools7.jpg"
import tools8 from "../../images/tools8.jpg"



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,

}));


const Four = () => {
    return (
        <div className="four_details">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0.5}  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3} >
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools1}


                        />
                        <Typography>Инструменты</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools2}

                        />
                        <Typography>Сантехника</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools3}

                        />
                        <Typography>Водоснабжение</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools4}

                        />
                        <Typography>Строительные материалы</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools5}

                        />
                        <Typography>Отделочные материалы</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools6}

                        />
                        <Typography>Электрика</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools7}

                        />
                        <Typography>Двери и окна</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            sx={{ height: 200, width: 200 }}
                            image={tools8}

                        />
                        <Typography>Освещение</Typography>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default Four;
