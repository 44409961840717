import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import Home from "./screens/home/home.js";
import Navbar from "./components/navbar/navbar.js";
import One from "./components/one/one";
import Two from "./components/two/two";
import Five from "./components/five/five";
import Four from "./components/four/four";
import Three from "./components/three/three";


function App() {
  return (
      <BrowserRouter>
          <Navbar />
          <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/auto' element={<One />} />
              <Route path='/computer' element={<Two />} />
              <Route path='/dental' element={<Three />} />
              <Route path='/house' element={<Four />} />
              <Route path='/coffee' element={<Five />} />
          </Routes>

      </BrowserRouter>
  );
}

export default App;
