import React from 'react';

import './home.css'
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <div className="main">
            <div className="main_text"> Мы собрали все в одном месте</div>
            <div className="home">
                <div className="one">
                    <div className="one_bg">
                      <p>22</p>
                        auto
                      <p>elementm.kg</p>
                    </div>
                    <Link className="one_mask" to="/auto">auto</Link>
                </div>
                <div className="two">
                   <div className="two_bg">
                     <p>46</p>
                       computer
                     <p>elementm.kg</p>
                   </div>
                   <Link className="two_mask" to="/computer">computer</Link>
                </div>
                <div className="three">
                    <div className="three_bg">
                        <p>52</p>
                          dental
                        <p>elementm.kg</p>
                    </div>
                    <Link className="three_mask" to="/dental">dental</Link>
                </div>
                <div className="four">
                    <div className="four_bg">
                       <p>36</p>
                        house
                       <p>elementm.kg</p>
                    </div>
                    <Link className="four_mask" to="/house">house</Link>
                </div>
                <div className="five">
                    <div className="five_bg">
                      <p>76</p>
                        coffee
                      <p>elementm.kg</p>
                    </div>
                    <Link className="five_mask" to="/coffee">coffee</Link>
                </div>


            </div>

        </div>

    );
};

export default Home;
