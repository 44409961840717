import React from 'react';
import './three.css'

const Three = () => {
    return (
        <div className="three_details">
             <h2>Стоматологическое оборудование:</h2>
            <div className="three_details_box">
                <div className="three_details_bg1">
                    <div>
                        <h3>Стоматологические установки</h3>
                        <p></p>

                    </div>
                </div>
                <div className="three_details_bg2">
                    <div>
                        <h3>Портативные стоматологические установки</h3>
                        <p></p>

                    </div>
                </div>
                <div className="three_details_bg3">
                    <div>
                        <h3>Зуботехническое оборудование</h3>
                        <p></p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Three;
